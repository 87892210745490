<template>
  <div id="app1" class="app-container" v-loading="isFetching">
    <el-menu :default-active="activeIndex" class="el-menu-dem" mode="horizontal" @select="handleSelect">
      <el-menu-item index="0">全部</el-menu-item>
      <el-menu-item index="1">未检测</el-menu-item>
      <el-menu-item index="2">合格</el-menu-item>
      <el-menu-item index="3">不合格</el-menu-item>
    </el-menu>
    <div class="header query-wrap">
      <div class="query">
        <div class="session1 clearfix item">
          <div class="shopBox" ref="shopBox" @click="treeClick">
            <i>组织：</i>
            <input type="text" readonly name="" id="" :value="checkedName" />
            <span class="el-tree-node__expand-icon el-icon-caret-right expanded"></span>
          </div>
          <div class="treeBox" ref="treeBox" v-show="treeIsShow">
            <el-input
              placeholder="请输入门店名称搜索"
              v-model="searchOrg"
              @change="searchOrg"
              class="input-with-select"
            >
              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>
            <el-tree
              node-key="orgcode"
              :data="shopsTree"
              :props="defaultProps"
              ref="tree"
              :default-expanded-keys="[orgcode]"
              :filter-node-method="filterNode"
              :expand-on-click-node="false"
              :highlight-current="true"
              @node-click="handleNodeClick"
            >
            </el-tree>
            <div class="dialogBtn pt20 mb20 borderTop">
              <button @click="getOrgcode" type="button" class="ml20 ag-model-btn blue fts mr5">确定</button>
              <button @click="hideAll" type="button" class="ag-model-btn gray fts">取消</button>
            </div>
          </div>
        </div>
        <div class="item">
          <span class="font_14 ml10">是否公司资产：</span>
          <el-select v-model="isBelongto" placeholder="请选择" value-key="value" @change="overviewInit">
            <el-option v-for="item in belongtoOption" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <el-input
            placeholder="请输入摄像头编号、原设备ID、MAC-ID进行检索"
            v-model="keyword"
            class="input-with-select ml20 searchInput"
            @change="overviewInit()"
          >
            <el-button class="searchbtn" slot="append" icon="el-icon-search" @click="overviewInit()"></el-button>
          </el-input>
        </div>
      </div>
    </div>
    <div class="operation clearfix">
      <div style="position: relative" ref="customDialog" class="fr">
        <a class="downloadstyle bord" :href="downloadurl"><i class="fa fa-download fhd"></i></a>
      </div>
    </div>
    <div class="integratable">
      <el-table
        :data="tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)"
        @sort-change="sortChange"
        style="width: 100%"
      >
        <el-table-column prop="cameraid" sortable="custom" label="摄像头编号"> </el-table-column>
        <el-table-column prop="seqid" sortable="custom" show-overflow-tooltip align="right" label="原设备ID">
        </el-table-column>
        <el-table-column prop="macid" sortable="custom" align="right" label="MAC-ID"> </el-table-column>
        <el-table-column prop="vendorTxt" sortable="custom" align="left" label="摄像头品牌"></el-table-column>
        <el-table-column prop="createtime" sortable="custom" align="left" label="入库日期"> </el-table-column>
        <el-table-column prop="seqcheck" sortable="custom" align="left" label="检测结果">
          <template slot-scope="scope">
            <span type="text" size="small"
              ><i
                class="seqcheck"
                :class="scope.row.seqcheck === 1 ? 'nocheck' : scope.row.seqcheck === 2 ? 'pass' : 'nopass'"
              ></i
              >{{ scope.row.seqcheck === 1 ? '未检测' : scope.row.seqcheck === 2 ? '合格' : '不合格' }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="groupname" sortable="custom" align="left" label="商户名称"> </el-table-column>
        <el-table-column prop="shopname" sortable="custom" align="left" label="门店名称"> </el-table-column>
        <el-table-column prop="belongto" sortable="custom" align="left" label="是否公司资产" width="120">
          <template slot-scope="scope">
            <span type="text" size="small">{{ scope.row.belongto === 1 ? '是' : '否' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" min-width="100">
          <template slot-scope="scope">
            <el-button @click="asset(scope.row)" type="text" size="small">资产标识</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="flexend">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[10, 30, 50]"
        :page-size="100"
        layout="total,sizes, prev, pager, next"
        :total="tableData.length"
      >
      </el-pagination>
    </div>
    <el-dialog title="资产标识" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <div>摄像头是否为开域资产？</div>
      <el-radio-group v-model="property">
        <el-radio :label="1">是</el-radio>
        <el-radio :label="0">否</el-radio>
      </el-radio-group>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="setBelonging">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import treemixin from 'src/mixins/tree'
import { upgrade, getStocklist, setBelonging } from 'src/api/legacy'

var httpUrl = '/newretail'
var storeHttp = '/newretail'
var storeHttpV2 = '/newretail/v2'

export default {
  mixins: [treemixin],
  data() {
    return {
      activeIndex: '0',
      keyword: '',
      fonturl: httpUrl, //测试环境请求前缀
      currentPage: 1,
      pageSize: 10,
      tableData: [],
      dialogVisible: false,
      property: 1,
      cameraid: '',
      isBelongto: -1,
      belongtoOption: [
        {
          value: -1,
          label: '全部',
        },
        {
          value: 1,
          label: '是',
        },
        {
          value: 0,
          label: '否',
        },
      ],
      isFetching: false,
    }
  },
  methods: {
    handleSelect(key) {
      //menu切换
      this.activeIndex = key
      this.currentPage = 1
      this.overviewInit()
    },
    getseqList() {
      let data = {
        keyword: this.keyword,
        seqcheck: this.activeIndex,
        orgcode: this.orgcode,
        belongto: this.isBelongto,
      }
      this.isFetching = true
      getStocklist(data).then((res) => {
        if (res && res.status === 0) {
          this.tableData = res.data
          this.currentPage = 1
          this.isFetching = false
        }
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
    },
    handleCurrentChange(val) {
      this.currentPage = val
    },
    overviewInit() {
      //页面初始化
      this.getseqList()
    },
    sortChange(column) {
      const { prop } = column
      const order = column.order === 'ascending' ? 1 : -1
      this.tableData.sort((a, b) => {
        return a[prop] > b[prop] ? order : -order
      })
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then((_) => {
          done()
        })
        .catch((_) => {})
    },
    asset(row) {
      this.dialogVisible = true
      this.cameraid = row.cameraid
    },
    setBelonging() {
      let data = {
        cameraid: this.cameraid,
        belongto: this.property,
      }
      setBelonging(data).then((res) => {
        if (res.data && res.status === 0) {
          this.dialogVisible = false
          this.$message({
            message: '操作成功',
            type: 'success',
          })
          this.getseqList()
        }
      })
    },
  },
  watch: {},
  computed: {
    downloadurl() {
      return (
        storeHttp +
        '/api/camera/downloadseq?keyword=' +
        this.keyword +
        '&seqcheck=' +
        this.activeIndex +
        '&orgcode=' +
        this.orgcode +
        '&belongto=' +
        this.isBelongto
      )
    },
  },
  created() {},
  mounted() {
    let CameraStock = JSON.parse(sessionStorage.getItem('CameraStock'))
    this.isFetching = true
    this.getTreeData()
      .then(async () => {
        //等树组织请求完成再执行
        if (CameraStock === null) {
          //缓存
          console.log('没有缓存')
        } else {
          this.activeIndex = CameraStock.active
          this.orgcode = CameraStock.code !== '' ? CameraStock.code : this.orgcode
          this.keyword = CameraStock.keyword
          if (CameraStock.treeName) {
            this.checkedName = CameraStock.treeName
          }
          this.isBelongto = CameraStock.belongto
        }
        await this.overviewInit()
        this.$refs.tree.setCurrentKey(this.orgcode)
      })
      .finally(() => {
        // this.isFetching = false
      })
    window.onbeforeunload = (e) => {
      //缓存
      let obj = {
        active: this.activeIndex,
        code: this.orgcode,
        keyword: this.keyword,
        treeName: this.checkedName,
        belongto: this.isBelongto,
      }
      sessionStorage.setItem('CameraStock', JSON.stringify(obj))
    }
  },
}
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.app-container {
  padding-top: 12px;
}
.header {
  margin-top: 12px;
}
.shopBox {
  display: inline-block;
}
.titletop {
  width: 100%;
  padding: 20px 0px;
  border-bottom: 1px solid #e6e6e6;
}
.rightnav {
  height: 60px;
  line-height: 60px;
}
.el-button.newbtn {
  width: 60px;
  height: 30px;
  line-height: 28px;
  text-align: center;
  padding: 0;
  color: #fff;
  background: #5c76cc;
  border-color: #5c76cc;
  border-radius: 2px;
  font-size: 12px;
}
.batchbtn {
  width: 80px;
  height: 30px;
  line-height: 28px;
  text-align: center;
  padding: 0;
  background: #f4f5f7;
  border-radius: 2px;
  border-color: #d1d1d1;
  font-size: 12px;
  margin-left: 10px;
}
.el-select .el-input {
  width: 318px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
.searchInput {
  width: 350px;
}
/* 按钮 */
.newbtn.el-button.is-plain:active {
  color: #fff;
  background: #5c76cc;
  border-color: #5c76cc;
  outline: 0;
}
.newbtn.el-button.is-active,
.newbtn.el-button.is-plain:active {
  color: #fff;
  background: #5c76cc;
  border-color: #5c76cc;
}
.newbtn.el-button.is-plain:focus,
.newbtn.el-button.is-plain:hover {
  color: #fff;
  background: #5c76cc;
  border-color: #5c76cc;
  width: 60px;
  height: 30px;
}
.newbtn.el-button:active {
  color: #fff;
  background: #5c76cc;
  border-color: #5c76cc;
  outline: 0;
}
.newbtn.el-button:focus,
.newbtn.el-button:hover {
  color: #fff;
  background: #5c76cc;
  border-color: #5c76cc;
  width: 60px;
  height: 30px;
}

.batchbtn.el-button.is-plain:active {
  color: #333;
  background: #f4f5f7;
  outline: 0;
}
.batchbtn.el-button.is-active,
.batchbtn.el-button.is-plain:active {
  color: #333;
  background: #f4f5f7;
}
.batchbtn.el-button.is-plain:focus,
.batchbtn.el-button.is-plain:hover {
  color: #333;
  background: #f4f5f7;
  border-color: #d1d1d1;
  width: 80px;
  height: 30px;
}
.batchbtn.el-button:active {
  color: #333;
  background: #f4f5f7;
  border-color: #d1d1d1;
  outline: 0;
}
.batchbtn.el-button:focus,
.batchbtn.el-button:hover {
  color: #333;
  background: #f4f5f7;
  border-color: #d1d1d1;
  width: 80px;
  height: 30px;
}

.flexend {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: flex-end;
}
.popupStyle {
  width: 420px;
  position: absolute;
  top: 33px;
  left: -298px;
  background-color: #ffffff;
  border-radius: 2px;
  z-index: 99;
}
.chooseProp {
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.trend-layout-block {
  position: relative;
  padding: 15px;
}
.trend-layout-block label input {
  margin-right: 7px;
}
.popupStyle label {
  width: 128px;
  margin-bottom: 15px;
}
.downloadstyle {
  width: 61px;
  height: 30px;
  position: relative;
  bottom: -1px;
  line-height: 27px;
  border-radius: 2px;
  text-align: center;
  color: #000;
  font-size: 14px;
  background-color: #f4f5f7;
  z-index: 1;
}
.add-indent label {
  width: 121px;
  height: 16px;
  overflow: hidden;
}

.el-select .el-input,
.el-select .el-input .el-input__inner,
.el-form-item__content .el-input .el-input__inner {
  width: 200px;
}
.el-select-dropdown.el-popper {
  min-width: 200px !important;
}

.seqcheck {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin-right: 10px;
}
.pass {
  background-color: #52cc8f;
}
.nopass {
  background-color: #e65c5c;
}
.nocheck {
  background-color: #5c76cc;
}
.AssetBtn::before {
  content: '';
  height: 5px;
  border-left: 1px solid #409eff;
  margin-right: 12px;
}
.el-dialog__body {
  padding-top: 20px;
}
.el-dialog__body .el-radio-group {
  margin-top: 17px;
}
</style>